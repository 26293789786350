import React from 'react';
import { useNavigate } from "react-router-dom";

const PropertyRentSale = ({ rentsaleVlaue, activeArray }) => {
    const navigate = useNavigate();
    const url = window.location.pathname;

    const handleCheckboxChange = (option) => {
        let result = url.replace("/search/", "").trim();
        let filters = result.split('+').map(filter => filter.trim()).filter(Boolean);

        if (filters.includes(option)) {
            return;
        } else {
            filters = filters.filter(filter => filter !== 'rent' && filter !== 'sale');
            filters.push(option);
        }
        const updatedResult = filters.join('+');
        navigate(`../search/${updatedResult}`);
        rentsaleVlaue(option);
    };

    return (
        <div className="filter">
            <div className="filterhead">
                <h6>For</h6>
                <i className="fa-solid fa-angle-down"></i>
            </div>
            <div className='salerent-switch'>
                <div className='propertyrentsale'>
                    <input 
                        type="radio"
                        name='rs' 
                        checked={activeArray.includes('rent')}
                        onChange={() => handleCheckboxChange("rent")}
                    />
                    <p>Rent</p>
                </div>
                <div className='propertyrentsale'>
                    <input 
                        type="radio"
                        name='rs' 
                        checked={activeArray.includes('sale')}
                        onChange={() => handleCheckboxChange("sale")}
                    />
                    <p>Sale</p>
                </div>
            </div>
        </div>
    );
};

export default PropertyRentSale;
